export default defineStore('AnchorStore', {
    state: () => ({
        anchors: [],
    }),

    actions: {
        addAnchor(anchor) {
            this.anchors.push(anchor);
            this.anchors = this.anchors.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
        },

        resetAnchors() {
            this.anchors = [];
        },
    },

    getters: {
        getAnchors(state) {
            return state.anchors;
        },
    },
});
