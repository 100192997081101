// eslint-disable-next-line import/prefer-default-export
export const useStoryStore = defineStore('StoryStore', {
    state: () => ({
        shopItem: {},
        data: {},
    }),

    actions: {
        /* Fetch current story from storyblok */
        async fetchCurrentStory(productIdentifier = null) {
            const storyblokApi = useStoryblokApi();

            const route = useRoute();
            const runtimeConfig = useRuntimeConfig();
            const { setContext } = useContextStore();
            const generalVersion = route.query._storyblok ? 'draft' : 'published';

            const version = runtimeConfig.public.USE_STORYBLOK_DRAFT === 'true' ? 'draft' : generalVersion;
            const path = route.path === '/' ? '/home' : route.path;

            try {
                if (!productIdentifier) {
                    const storyblokData = await storyblokApi.get(`cdn/stories${path}`, {
                        version,
                    });

                    this.data = storyblokData?.data;
                    setContext({
                        strapiCollection: storyblokData?.data?.story?.content?.strapi_collection,
                        strapiId: storyblokData?.data?.story?.content?.strapi_id,
                        strapiSpeakerId: storyblokData?.data?.story?.content?.strapi_speaker_id,
                        strapiBodyAreaId: storyblokData?.data?.story?.content?.strapi_body_area_id,
                        strapiBodyPartId: storyblokData?.data?.story?.content?.strapi_body_part_id,
                        strapiBrandId: storyblokData?.data?.story?.content?.strapi_brand_id,
                        strapiConsultationId:
                        storyblokData?.data?.story?.content?.strapi_consultation_id,
                        strapiDossierId: storyblokData?.data?.story?.content?.strapi_dossier_id,
                        strapiEventId: storyblokData?.data?.story?.content?.strapi_event_id,
                        strapiFocusTopicId:
                        storyblokData?.data?.story?.content?.strapi_focus_topic_id,
                        strapiIndicationId:
                        storyblokData?.data?.story?.content?.strapi_indication_id,
                        strapiJobId: storyblokData?.data?.story?.content?.strapi_job_id,
                        strapiMedicalPlantId:
                        storyblokData?.data?.story?.content?.strapi_medical_plant_id,
                        strapiManufacturerId:
                        storyblokData?.data?.story?.content?.strapi_manufacturer_id,
                        strapiNaturalSolutionProductId:
                        storyblokData?.data?.story?.content?.strapi_natural_solution_product_id,
                        strapiProductId: storyblokData?.data?.story?.content?.strapi_product_id,
                        strapiRecommendationProductId:
                        storyblokData?.data?.story?.content?.strapi_recommendation_product_id,
                        strapiRelatedIndicationId:
                        storyblokData?.data?.story?.content?.strapi_related_indication_id,
                        strapiStoreId:
                        storyblokData?.data?.story?.content?.strapi_store_id,
                        strapiTherapyFormId:
                        storyblokData?.data?.story?.content?.strapi_therapy_form_id,
                    });
                    return;
                }
                const { findOne } = useStrapi4();
                const [storyblokData, strapiData] = await Promise.all([
                    storyblokApi.get('cdn/stories/produkte/product-preset', {
                        version,
                    }),

                    findOne('shop-items', productIdentifier),
                ]);

                /*
                    add strapi data to storyBlokData.data.story.strapiData
                */
                const productData = strapiData?.updated_product
                    || strapiData?.product
                    || strapiData;
                storyblokData.data.story.content.strapi_data = productData;
                this.shopItem = strapiData;
                this.data = storyblokData.data;
            } catch (e) {
                let error = null;
                if (typeof e === 'string') {
                    try {
                        error = JSON.parse(e);
                    } catch (err) {
                        error = err;
                    }
                } else {
                    error = e;
                }
                throw createError({
                    statusCode: error.status,
                    message: error.status === 404
                        ? 'Diese Seite existiert leider nicht.' : error.response,
                });
            }
        },
    },

    getters: {
        /* Getter to get connected shop item if it's a product page */
        getConnectedShopitem(state) {
            return state.shopItem;
        },

        /* Getter to get the current stories json */
        getCurrentStory(state) {
            return state.data;
        },

        /* Getter to get the current stories name */
        getCurrentStoryName(state) {
            return state.data.story.name;
        },

        /* Getter to get the current stories name */
        getCurrentStoryFirstPublishedDate(state) {
            return state.data.story.first_published_at;
        },

        /* Getter to check if current page is a startpage */
        isCurrentStoryRoot(state) {
            return state.data.story.is_startpage;
        },

        /* Getter to get the whole strapi data json */
        getConnectedStrapiData(state) {
            const strapiData = state.data?.story?.content?.strapi_data;
            if (!strapiData) return '';

            if (typeof strapiData === 'string') {
                return JSON.parse(strapiData);
            }

            return strapiData;
        },

        /* Getter to get strapi data by fieldname */
        getConnectedStrapiValue: (state) => (key) => {
            if (!key) {
                throw createError({
                    statusCode: '500',
                    statusMessage: 'Fatal: Required Param key is missing @useStoryStore @getConnectedStrapiData',
                });
            }

            const strapiData = state.data?.story?.content?.strapi_data;

            if (!strapiData) return '';

            if (typeof strapiData === 'string') {
                const json = JSON.parse(strapiData);
                const res = json ? json[key] : '';
                return res;
            }

            if (typeof strapiData === 'object') {
                return strapiData[key];
            }

            return strapiData.attributes[key];
        },

        getConnectedCuratedStrapiProducts: (state) => (keys) => {
            const strapiData = state.data?.story?.content?.strapi_data;

            if (!strapiData) return [];

            let parsedData = null;
            if (typeof strapiData === 'string') {
                parsedData = JSON.parse(strapiData);
            } else {
                parsedData = strapiData;
            }

            if (!parsedData) return [];

            if (keys.length === 1) {
                return parsedData[keys[0]]?.map((entry) => entry.id);
            }

            const topLevelData = parsedData[keys[0]];
            let items = [];

            topLevelData?.forEach((branch) => {
                const entries = branch[keys[1]]?.map((entry) => entry.id) || [];
                items = [...items, ...entries];
            });

            return items;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoryStore, import.meta.hot));
}
