<template>
  <component
    :is="computedValues.tag"
    v-if="textRender"
    class="atom-heading"
    :class="[computedValues.appereance, computedValues.gridStart, layout]"
  >
    {{ textRender }}
  </component>
</template>

<script setup>
const props = defineProps({
    /* Prop for storyblok dkata */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for manual calling */
    tag: {
        type: String,
        default: 'h2',
    },

    appereance: {
        type: String,
        default: 'h2',
    },

    text: {
        type: String,
        default: '',
    },

    layout: {
        type: String,
        default: '',
    },
});
const { currentRoute } = useRouter();
const routeName = currentRoute.value.name;

/* Computed Storyblok Data */
const computedValues = computed(() => ({
    tag: props.data?.tag || props.tag || 'h2',
    appereance: props.data?.appereance || props.appereance || 'h2',
    text: props.data?.text || props.text || '',
    gridStart: routeName === 'produkte-id' && 'wide',
}));

const user = useStrapiUser();

const replacements = computed(() => ({
    '{{USER_NAME}}': user?.value?.username || '',
    '{{USER_FIRSTNAME}}': user?.value?.firstName || '',
    '{{USER_LASTNAME}}': user?.value?.lastName || '',
}));

const storyStore = useStoryStore();

const textRender = computed(() => {
    let string = computedValues.value.text;

    if (props.data?.strapi_data_key) {
        string = storyStore.getConnectedStrapiValue(props.data.strapi_data_key);
    }

    /*
        If element relates to antoher strapi element check if content is there
        and if not do not render this compo
    */
    if (props.data?.relates_to_key) {
        const relatedStrapiData = storyStore.getConnectedStrapiValue(props.data.relates_to_key);
        if (!relatedStrapiData) {
            return '';
        }
    }

    Object.entries(replacements?.value).forEach((entry) => {
        const [placeholder, value] = entry;
        string = string ? string.replaceAll(placeholder, value) : '';
    });

    return string;
});
</script>

<style lang="scss">
.atom-heading {
    @include typo-font('black');

    @include top-level-only{
        @include default-content-columns;
    }

    color: $C_GREEN_DARK;

    &.wide {
        @include default-content-columns(true);
    }

    &.h1 {
        @include typo-size('h1');
        @include component-spacing(10px, 40px);
    }

    &.h2 {
        @include typo-size('h2');
        @include component-spacing(10px, 20px);
        @include typo-font('regular');
    }

    &.h3 {
        @include typo-size('h3');
        @include component-spacing(10px, 20px);
        @include typo-font('regular');
    }

    &.h4 {
        @include typo-size('h4');
        @include component-spacing(10px, 20px);
    }

    & + .block-subnav {
        @include fluid('margin-top', 25px, 25px);
    }

    & + .auth-login__grid {
        @include fluid('margin-top', 30px, 90px);
    }
}
</style>
