/*
    Client Init Plugin
    @description: Everything in here will be resolved one time on client side
    @author: Luca Margadant <luca.margadant@nueva.ch>
*/

export default defineNuxtPlugin(async (/* nuxtApp */) => {
    if (process.client) {
        /*
            Initialize clientDevice store
        */
        const clientDeviceStore = useClientDeviceStore();

        clientDeviceStore.setOnClient();
        clientDeviceStore.setOnResize();

        window.addEventListener('scroll', () => {
            clientDeviceStore.setOnScroll();
        }, { passive: true });

        window.addEventListener('resize', () => {
            clientDeviceStore.setOnResize();
        }, { passive: true });

        /*
            Bookmarks
        */
        const bookmarkStore = useBookmarkStore();

        bookmarkStore.initBookmarks();

        /*
            Initialize Appsize helper
        */
        useAppsize();
    }
});
