export default defineStore('BookmarkStore', {
    state: () => ({
        items: [],
        initialized: false,
    }),

    actions: {
        initBookmarks() {
            const user = useStrapiUser();

            if (user.value && user.value?.bookmarks?.length > 0) {
                const storageBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
                const savedBookmarks = user.value.bookmarks.map((object) => object);
                this.items = [...this.items, ...storageBookmarks, ...savedBookmarks];
            }

            if (!user.value) {
                this.items = JSON.parse(localStorage.getItem('bookmarks')) || [];
            }

            this.initialized = true;
        },

        async syncBookmarks() {
            const { items } = this;
            const { update } = useStrapi4();
            const { getClientID } = useGTM();
            const clientId = getClientID();

            try {
                const { sendEvent } = useGTM();

                sendEvent({
                    event: 'add_to_wishlist',
                    ecommerce: {
                        value: null,
                        items,
                    },
                });

                const user = useStrapiUser();

                if (user.value) {
                    const storageBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
                    const bookmarks = [...items, ...storageBookmarks];
                    await update('users-permissions/users/me', {
                        bookmarks,
                        clientId,
                    });
                } else {
                    localStorage.setItem('bookmarks', JSON.stringify(items));
                }
            } catch (e) {
                useSentryError(e);
            }
        },

        async addBookmark(item) {
            this.items.push(item);
            this.syncBookmarks();
        },

        async removeBookmark(data) {
            const items = this.items.filter((item) => data.shopItemId !== item.shopItemId);
            this.items = items;

            this.syncBookmarks();
        },
    },

    getters: {
        getBookmarks(state) {
            return state.items;
        },

        getInitialized(state) {
            return state.initialized;
        },

        itemIsBookmarked: (state) => (data) => {
            const storageBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
            const isBookmarked = [...state.items, ...storageBookmarks].some(
                (item) => item.id === data.id && item.type === data.type,
            );

            return isBookmarked;
        },
    },
});
