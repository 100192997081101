import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93i4QJC4pz5gMeta } from "/vercel/path0/pages/landingpages/[...slug].vue?macro=true";
import { default as _91id_93qnPHQq6a8jMeta } from "/vercel/path0/pages/produkte/[id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "landingpages-slug",
    path: "/landingpages/:slug(.*)*",
    meta: _91_46_46_46slug_93i4QJC4pz5gMeta || {},
    component: () => import("/vercel/path0/pages/landingpages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "produkte-id",
    path: "/produkte/:id()",
    component: () => import("/vercel/path0/pages/produkte/[id].vue").then(m => m.default || m)
  }
]