export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('anchors', {
        created(el, data) {
            const anchorStore = useAnchorStore();

            const { index, item } = data.value;
            const anchorText = item.anchor_text;

            if (anchorText) {
                const anchorId = slugify(anchorText);
                el.setAttribute('id', anchorId);
                anchorStore.addAnchor({
                    id: anchorId,
                    text: anchorText,
                    index,
                });
            }
        },
    });
});
