<template>
  <div>
    <NuxtLayout layout="default">
      <div>
        <NuxtPage />
      </div>
    </NuxtLayout>
  </div>
</template>
<script setup>
const route = useRoute();
const storyblokApi = useStoryblokApi();
const { public: runtimeConfig } = useRuntimeConfig();
const { USE_STORYBLOK_DRAFT } = runtimeConfig;

/*
  Initialize variables
*/
const generalVersion = route.query._storyblok ? 'draft' : 'published';
const version = USE_STORYBLOK_DRAFT === 'true' ? 'draft' : generalVersion;
provide('storyblokVersion', version);

/*
  Get settings from storyblok
*/

const { error } = await useAsyncData(
    'storyblok-settings-data',
    () => storyblokApi.get(
        'cdn/stories/',
        {
            version,
            starts_with: 'settings/',
            resolve_links: 'link',
        },
    ),
);

if (error.value) {
    throw createError({
        statusCode: '500',
        statusMessage: 'Whoops! Failed to fetch settings from storyblok @App.vue',
    });
}
</script>
<style lang="scss">
@import '@/assets/scss/resets';
@import '@/assets/scss/root';
@import '@/assets/scss/transitions';
@import '@/assets/scss/swiperjs';

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-leave-to {
    opacity: 0;
}

.page-enter-from {
    opacity: 0;
}
</style>
