import { useIntersectionObserver } from '@vueuse/core';

export default (el) => {
    const isLoaded = ref(false);
    const hasOpacity = ref(0);

    const loadImage = () => {
        isLoaded.value = true;
    };

    const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
        if (isIntersecting) {
            loadImage();
            stop();
        }
    });

    return {
        isLoaded,
        hasOpacity,
    };
};
