<template>
  <button
    class="atom-button"
    :disabled="isDisabled ? true : false"
    :type="computedValues.button_type"
    :class="[
      computedValues.appereance,
      { 'is-small': isSmall },
    ]"
  >
    {{ computedValues.text }}
  </button>
</template>

<script setup>
const props = defineProps({
    /* Props for storyblok data */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for manual calling */
    buttonType: {
        type: String,
        default: 'button', // button || submit || reset
    },
    appereance: {
        type: String,
        default: 'primary', // primary || secondary || inverted
    },
    text: {
        type: String,
        default: '',
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    isSmall: {
        type: Boolean,
        default: false,
    },
});

/* Computed Storyblok Data */
const computedValues = computed(() => ({
    button_type: props.data?.button_type || props.buttonType,
    appereance: props.data?.appereance || props.appereance,
    text: props.data?.text || props.text,
}));
</script>

<style lang="scss" scoped>
.atom-button {
    @include typo-size('button');
    @include typo-font('regular');
    @include fluid('height', 49px, 59px);
    @include fluid('min-width', 152px, 172px);

    &.is-small{

        @include mq(700) {
            min-width: 0;
            padding: 15px 20px;
        }
    }

    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    border: none;
    border: 2px solid $C_SECONDARY;
    border-radius: 6px;
    background: $C_SECONDARY;
    color: $C-WHITE;
    outline: 0;
    text-align: center;
    transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out, border 0.25s ease-in-out, color 0.25s ease-in-out;
    white-space: nowrap;

    &.secondary {
        background: $C_BLUE;
    }

    &.inverted {
        border: 2px solid $C_SECONDARY;
        background: transparent;
        color: $C_SECONDARY;

        &:hover {
            border-color: $C_PRIMARY;
            background: transparent;
            color: $C_PRIMARY;
        }
    }
    &.inverted-no-border {
        background: transparent;
        color: $C_SECONDARY;

        &:hover {
            background: transparent;
            color: $C_PRIMARY;
        }
    }

    &:hover {
        border-color: $C_PRIMARY;
        background-color: $C_PRIMARY;
    }

    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    @include mobile {
        padding: 15px 30px;
    }
}

.form-fieldset + .atom-button {
    @include fluid('margin-top', 20px, 40px);
}
</style>
