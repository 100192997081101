const title = ref('');

export default () => {
    const setData = (seoToRender) => {
        title.value = seoToRender?.title || '';
        const meta = [];

        if (seoToRender?.description) {
            meta.push({
                hid: 'description',
                name: 'description',
                content: seoToRender?.description || '',
            });
        }

        if (seoToRender?.og_title) {
            meta.push({
                hid: 'og-title',
                property: 'og:title',
                content: seoToRender?.og_title || '',
            });
        }

        if (seoToRender?.og_description) {
            meta.push({
                hid: 'og-description',
                property: 'og:description',
                content: seoToRender?.og_description || '',
            });
        }

        if (seoToRender?.og_image) {
            meta.push({
                hid: 'og-image',
                property: 'og:image',
                content: seoToRender?.og_image?.filename || seoToRender?.og_image || '',
            });
        }

        if (seoToRender?.twitter_title) {
            meta.push({
                hid: 'twitter-card',
                property: 'twitter:card',
                content: 'summary',
            });

            meta.push({
                hid: 'twitter-title',
                property: 'twitter:title',
                content: seoToRender?.twitter_title || '',
            });
        }

        if (seoToRender?.twitter_description) {
            meta.push({
                hid: 'twitter-description',
                property: 'twitter:description',
                content: seoToRender?.twitter_description || '',
            });
        }

        if (seoToRender?.twitter_image) {
            meta.push({
                hid: 'twitter-image',
                property: 'twitter:image',
                content: seoToRender?.twitter_image || '',
            });
        }

        useHead({
            title: `${seoToRender?.title || ''}`,
            meta,
        });
    };

    const getTitle = () => title.value;

    return {
        setData,
        getTitle,
    };
};
