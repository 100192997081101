/*
  Redirects
  For authenticated and public users
*/
/* eslint-disable consistent-return */
export default defineNuxtRouteMiddleware(async (to) => {
    const redirectSettings = computed(() => getStoryblokSetting('redirects'));

    const user = useStrapiUser();

    /* Remove beginning slash and add ending slash to match storyblok cached_url schema */
    const currentSlug = `${to.fullPath.substring(1)}/`;

    let currentPageRedirect;

    /* Check if current slug includes redirect slug */
    const findRedirectElement = (redirectElement) => currentSlug.includes(
        redirectElement.redirect_from.cached_url,
    );

    const redirectHandler = (currentRedirect) => {
        if (
            !currentRedirect?.redirect_from?.cached_url
            && !currentRedirect?.redirect_to?.cached_url
        ) {
            return;
        }

        return navigateTo(`/${currentRedirect.redirect_to.cached_url}`, {
            replace: true,
        });
    };

    if (user?.value) {
        currentPageRedirect = redirectSettings?.value?.redirects_authenticated?.find(
            (element) => findRedirectElement(element),
        );
    } else {
        currentPageRedirect = redirectSettings?.value?.redirects_public?.find(
            (element) => findRedirectElement(element),
        );
    }

    // 301 Redirects
    const customRedirect = redirectSettings?.value?.redirects_301
        ?.find((el) => {
            // Remove trailing slashes from both slugs
            const cleanSlug1 = el?.source_url?.replace(/^\/+/, '');
            const cleanSlug2 = currentSlug.replace(/\/+$/, '');
            return cleanSlug1 === cleanSlug2;
        });

    if (customRedirect) {
        return navigateTo(customRedirect?.target_url, {
            redirectCode: 301,
            external: customRedirect?.is_external,
        });
    }

    return redirectHandler(currentPageRedirect);
});
