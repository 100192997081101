<template>
  <div v-if="computedValues.text" class="atom-lead" :class="[computedValues.layout]">
    {{ computedValues.text }}
  </div>
</template>

<script setup>
const props = defineProps({
    /* Prop for storyblok dkata */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for manual calling */
    text: {
        type: String,
        default: '',
    },

    layout: {
        type: String,
        default: '',
    },
});

/*
    Computed Data
*/
const storyStore = useStoryStore();

const computedValues = computed(() => {
    let text = props.data?.text || props.text;

    if (props.data?.strapi_data_key) {
        text = storyStore.getConnectedStrapiValue(props.data.strapi_data_key);
    }

    return {
        text,
        layout: props.data?.layout || props.layout,
    };
});

</script>

<style lang="scss">
.atom-lead {
    @include component-spacing(30px, 60px);
    @include typo-size('lead');
    @include typo-font('light');
    @include fluid('line-height', 26px, 32px);

    @include top-level-only {
        @include default-content-columns;
    }

    color: $C_GREEN_DARK;

    /*
      Follow margin corrections
    */
    & + .auth-registration {
        @include fluid('margin-top', 10px, 60px);
    }

    & + .block-folder-links {
        @include fluid('margin-top', 10px, 60px);
    }
}
</style>
