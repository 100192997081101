/*
    App init client side
    @description:
        Everything in here will be resolved as soon as possible
        No matter if on client or server
    @author: Luca Margadant <luca.margadant@nueva.ch>
*/

export default defineNuxtPlugin(async (/* nuxtApp */) => {
    // const settingsStore = useSettingsStore();
    // await settingsStore.fetch();

    // const filterStore = useFilterStore();
    // await filterStore.fetch();

    /*
        Dummy Login for storyblok preview
    */
    // const route = useRoute();
    // const user = useStrapiUser();
    //
    // if (route.query._storyblok && !user.value) {
    //     const runtimeConfig = useRuntimeConfig();
    //     const client = useStrapiClient();
    //
    //     try {
    //         const credentials = {
    //             identifier: runtimeConfig.DUMMY_USER_IDENTIFIER,
    //             password: runtimeConfig.DUMMY_USER_PASSWORD,
    //         };
    //
    //         await client('/admin/login', credentials);
    //     } catch (e) {
    //         useSentryError(e);
    //     }
    // }
});
