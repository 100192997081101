import VueGtag from 'vue-gtag';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueGtag, {
        bootstrap: false,
    });

    nuxtApp.vueApp.provide(
        'gtag',
        nuxtApp.vueApp.config.globalProperties.$gtag,
    );
});
