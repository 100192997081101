/*
        Checks if a given link is internal or not
        @param url (String) the url you want to check
    */
export const isInternalLink = (url) => {
    if (!url) {
        throw createError({
            statusCode: '500',
            statusMessage: 'Fatal: isInternalLink function expected url string @useHelpers',
        });
    }

    const runtimeConfig = useRuntimeConfig();
    if (!runtimeConfig.public.BASE_URL) {
        return !url.includes('http://')
        && !url.includes('https://')
        && !url.includes('mailto:')
        && !url.includes('tel:');
    }

    const base = new URL(runtimeConfig.public.BASE_URL);
    return new URL(url, base).hostname === base.hostname && !url.includes('@');
};

export const urlExists = (url) => {
    const http = new XMLHttpRequest();
    http.open('HEAD', url, true);
    http.send();
    if (http.status !== 404) return true;
    return false;
};

export const slugify = (str) => str?.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-')
    ?.replace(/^-+|-+$/g, '');

export const removeGetParams = (str) => {
    const splitted = str.split('?');
    return splitted[0];
};

export const deepObjectLog = (obj) => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify({
        obj,
    }, null, 2));
};
export const toCamelCase = (str, seperator = '_') => {
    const splitted = str.split(seperator);
    const first = splitted[0];
    const rest = splitted.slice(1).map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return [first, ...rest].join('');
};
// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, Math.ceil(ms)));

/* Returns grouped arrays by property */
export const groupArrayByProperty = (arr, property) => arr.reduce((acc, cur) => {
    acc[cur[property]] = [...acc[cur[property]] || [], cur];

    return acc;
}, {});

export const round = (value, step = 0.05) => {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
};

export const mapOrder = (array, order, key, key2) => {
    array.sort((a, b) => {
        const A = a[key][key2];
        const B = b[key][key2];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        }
        return -1;
    });

    return array;
};

export const parseHTMLString = (text) => text?.replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, '\'')
    .replace(/&uuml;/g, 'ü')
    .replace(/&auml;/g, 'ä')
    .replace(/&ouml;/g, 'ö')
    .replace(/&Uuml;/g, 'Ü')
    .replace(/&Auml;/g, 'Ä')
    .replace(/&Ouml;/g, 'Ö')
    .replace(/&szlig;/g, 'ß')
    .replace(/<[^>]*>?/gm, '') || '';

export const allReplace = (str, obj) => {
    let string = str;
    Object.keys(obj).forEach((x) => {
        string = string.replace(new RegExp(x, 'g'), obj[x]);
    });
    return string;
};

const umlautMap = {
    Ü: 'UE',
    Ä: 'AE',
    Ö: 'OE',
    ü: 'ue',
    ä: 'ae',
    ö: 'oe',
    ß: 'ss',
};

export const replaceUmlauts = (str) => {
    if (!str) return '';

    return str.replace(/[\u00DC|\u00C4|\u00D6][a-z]/g, (a) => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    }).replace(
        new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'),
        (a) => umlautMap[a],
    );
};
export const removeTrailingSlash = (str) => (str.endsWith('/') ? str.slice(0, -1) : str);

export const pluralToSingular = (word) => {
    const rules = [
        [/(sh|ch|x)es$/, '$1'],
        [/([^aeiou])ies$/, '$1y'],
        [/([^aeiou])ys$/, '$1y'],
        [/(s)es$/, '$1'],
        [/([^s])s$/, '$1'],
        [/(s)$/, '$1'],
    ];

    const ruleMatch = rules.find(([pattern]) => word[0].match(pattern));

    if (ruleMatch) {
        const [pattern, replacement] = ruleMatch;
        return word[0].replace(pattern, replacement);
    }

    return word;
};

export const singularToPlural = (word) => {
    const rules = [
        [/(sh|ch|x)$/, '$1es'],
        [/([^aeiou])y$/, '$1ies'],
        [/([^aeiou])y$/, '$1ys'],
        [/(s)$/, '$1es'],
        [/([^s])$/, '$1s'],
        [/(s)$/, '$1s'],
    ];

    const ruleMatch = rules.find(([pattern]) => word.match(pattern));
    if (ruleMatch) {
        const [pattern, replacement] = ruleMatch;
        return word.replace(pattern, replacement);
    }

    return `${word}s`; // default to adding 's' if no specific rule applies
};

export const formatNumberToCurrency = (value, locale = 'de-CH', currency = 'CHF') => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    const formatted = formatter.format(value);

    // CHF 11'550.00
    const splitted = formatted.split(' ');
    return splitted[1];
};
