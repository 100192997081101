const parseData = (data) => {
    let returnValue = data;
    if (typeof data === 'string' && data.startsWith('[') && data.endsWith(']')) {
        try {
            const parsedArray = JSON.parse(data);
            if (Array.isArray(parsedArray) && parsedArray.every((item) => typeof item === 'number')) {
                returnValue = parsedArray;
            } else {
                returnValue = [];
            }
        } catch (error) {
            returnValue = [];
        }
    } else if (/^\d+$/.test(data)) {
        returnValue = parseInt(data, 10);
    }
    return returnValue !== '' ? returnValue : [];
};

export default defineStore('ContextStore', {
    state: () => ({
        strapiCollection: null,
        strapiId: null,
        strapiSpeakerId: null,
        strapiBodyAreaId: null,
        strapiBodyPartId: null,
        strapiBrandId: null,
        strapiConsultationId: null,
        strapiDossierId: null,
        strapiEventId: null,
        strapiFocusTopicId: null,
        strapiIndicationId: null,
        strapiJobId: null,
        strapiMedicalPlantId: null,
        strapiManufacturerId: null,
        strapiNaturalSolutionProductId: null,
        strapiProductId: null,
        strapiRecommendationProductId: null,
        strapiRelatedIndicationId: null,
        strapiStoreId: null,
        strapiTherapyFormId: null,
    }),

    actions: {
        /* Fetch current story from storyblok */
        async setContext(data) {
            this.strapiCollection = parseData(data.strapiCollection);
            this.strapiId = parseData(data.strapiId);
            this.strapiSpeakerId = parseData(data.strapiSpeakerId);
            this.strapiBodyAreaId = parseData(data.strapiBodyAreaId);
            this.strapiBodyPartId = parseData(data.strapiBodyPartId);
            this.strapiBrandId = parseData(data.strapiBrandId);
            this.strapiConsultationId = parseData(data.strapiConsultationId);
            this.strapiDossierId = parseData(data.strapiDossierId);
            this.strapiEventId = parseData(data.strapiEventId);
            this.strapiFocusTopicId = parseData(data.strapiFocusTopicId);
            this.strapiIndicationId = parseData(data.strapiIndicationId);
            this.strapiJobId = parseData(data.strapiJobId);
            this.strapiMedicalPlantId = parseData(data.strapiMedicalPlantId);
            this.strapiManufacturerId = parseData(data.strapiManufacturerId);
            this.strapiNaturalSolutionProductId = parseData(data.strapiNaturalSolutionProductId);
            this.strapiProductId = parseData(data.strapiProductId);
            this.strapiRecommendationProductId = parseData(data.strapiRecommendationProductId);
            this.strapiRelatedIndicationId = parseData(data.strapiRelatedIndicationId);
            this.strapiStoreId = parseData(data.strapiStoreId);
            this.strapiTherapyFormId = parseData(data.strapiTherapyFormId);
        },

    },

    getters: {
        getCollection: (state) => state.strapiCollection,
        getId: (state) => state.strapiId,
        getSpeakerId: (state) => state.strapiSpeakerId,
        getBodyAreaId: (state) => state.strapiBodyAreaId,
        getBodyPartId: (state) => state.strapiBodyPartId,
        getBrandId: (state) => state.strapiBrandId,
        getConsultationId: (state) => state.strapiConsultationId,
        getDossierId: (state) => state.strapiDossierId,
        getEventId: (state) => state.strapiEventId,
        getFocusTopicId: (state) => state.strapiFocusTopicId,
        getIndicationId: (state) => state.strapiIndicationId,
        getJobId: (state) => state.strapiJobId,
        getMedicalPlantId: (state) => state.strapiMedicalPlantId,
        getManufacturerId: (state) => state.strapiManufacturerId,
        getNaturalSolutionProductId: (state) => state.strapiNaturalSolutionProductId,
        getProductId: (state) => state.strapiProductId,
        getRecommendationProductId: (state) => state.strapiRecommendationProductId,
        getRelatedIndicationId: (state) => state.strapiRelatedIndicationId,
        getStoreId: (state) => state.strapiStoreId,
        getTherapyFormId: (state) => state.strapiTherapyFormId,
        getKeyByStrapiDataKey: (state) => (strapiDataKey) => state[strapiDataKey.replace(/_./g, (match) => match.charAt(1).toUpperCase())],
    },
});
