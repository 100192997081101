import revive_payload_client_vD5OkOCETf from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_p76y6HNUrW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7xfYoDUC5N from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_NHp7SxFReF from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.2.0_@vue+compiler-core@3.4.31_axios@0.27.2_nuxt@3.12_y6ck2z7vs2iprcg3vmz6gry7dm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_sgWxEVm3td from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Q9ONN6gsF1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NnwdzAzk4U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_eco207AQli from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@4.9.5_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5EedvdA62e from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_eslint@8.24.0_sass@1.75.0_stylelint@14.16.1_typescript@4.9.5_vite@3.2.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_lfY4MjKOHl from "/vercel/path0/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@4.9.5/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_BL70Y2Sgaj from "/vercel/path0/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_vue@3.4.31/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_mPX6tm7l4U from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@5.7.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import strapi_vYPqwPFOrc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+strapi@1.12.0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_d0O7ejDmWS from "/vercel/path0/plugins/app.init.client.js";
import chunk_error_handler_wTHYgqOJwi from "/vercel/path0/plugins/chunk-error-handler.js";
import global_directives_YDZ0YlEf08 from "/vercel/path0/plugins/global-directives.js";
import sb_preview_757msyeJ0g from "/vercel/path0/plugins/sb-preview.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
import vue_masonry_wall_6SfpdZr8v7 from "/vercel/path0/plugins/vue-masonry-wall.js";
import vue_social_sharing_client_1N3fV7xgEB from "/vercel/path0/plugins/vue-social-sharing.client.ts";
export default [
  revive_payload_client_vD5OkOCETf,
  unhead_p76y6HNUrW,
  router_7xfYoDUC5N,
  _0_siteConfig_NHp7SxFReF,
  payload_client_sgWxEVm3td,
  navigation_repaint_client_Q9ONN6gsF1,
  check_outdated_build_client_NnwdzAzk4U,
  plugin_vue3_eco207AQli,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5EedvdA62e,
  plugin_lfY4MjKOHl,
  plugin_BL70Y2Sgaj,
  plugin_mPX6tm7l4U,
  strapi_vYPqwPFOrc,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_d0O7ejDmWS,
  chunk_error_handler_wTHYgqOJwi,
  global_directives_YDZ0YlEf08,
  sb_preview_757msyeJ0g,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb,
  vue_masonry_wall_6SfpdZr8v7,
  vue_social_sharing_client_1N3fV7xgEB
]