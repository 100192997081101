<template>
  <div class="error-layout">
    <div class="error-layout__inner">
      <div class="error-layout__column">
        <AtomImage src="/images/saner-error.svg" alt="Saner Error Page Image" />
      </div>
      <div class="error-layout__column">
        <AtomHeading
          tag="h1"
          appereance="status"
          class="error-layout__status"
          :text="`${error.statusCode}`"
        />

        <AtomLead :text="`${error.message}...`" />
        <AtomButton
          text="Zur Startseite"
          appereance="primary"
          @click="handleError"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const handleError = () => {
    clearError({ redirect: '/' });
};
</script>

<style lang="scss">
.error-layout {
    height: var(--app-height);
    background: $C_GREEN_BRIGHT;
}
.error-layout__inner {
    @include grid-layout(2);

    width: 100%;
    max-width: var(--grid-width);
    height: 100%;
    padding: 0 var(--page-padding);
    margin: 0 auto;

    @include mobile {
        @include grid-layout(1);

    }
}

.error-layout__column {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .atom-image {
        width: 90%;

        @include mobile {
            width: 80%;
        }
    }

    &:last-child {
        flex-direction: column;
        row-gap: 26px;

        @include mobile {
            grid-row: 1;
        }
    }
}

.error-layout__status {
    font-size: 260px;
    @include fluid('font-size', 130px, 260px);
}
</style>
