import { createGtm } from '@gtm-support/vue-gtm';

const trackingAllowed = ref(false);
const debug = true;
const clientID = ref(null);
let $gtm = null;

export default () => {
    /*
        Settings
    */
    const route = useRoute();
    const { public: publicConfig } = useRuntimeConfig();
    const { getTitle } = useSEO();

    /*
        Helper function to check if tracking is allowed
    */
    const doNotTrack = () => trackingAllowed.value === false || publicConfig.USE_GA === 'false';

    /*
        Helper function to enable gtm
    */
    const _enableGTM = () => {
        if ($gtm && !$gtm.enabled()) {
            $gtm.enable(true);

            clientID.value = getCookie('_ga');

            if (debug) {
                $gtm.debug(true);
            }
        }
    };

    /*
        Helper function to send gtm events
    */
    const sendEvent = (options) => {
        if (doNotTrack()) return;
        if (Object.keys(options).includes('ecommerce')) {
            $gtm.trackEvent({
                ecommerce: null,
            });
        }
        $gtm.trackEvent(options);
    };

    /*
        Helper function to get client id
    */
    const getClientID = () => clientID.value;

    /*
        Helper fuction to send page views
    */
    const sendPageView = (path = route.path, name = getTitle()) => {
        if (doNotTrack()) return;
        $gtm.trackView(name, path);
    };

    const init = () => {
        /*
            Init GTM
        */
        const nuxtApp = useNuxtApp();
        const settings = computed(() => getStoryblokSetting('tracking'));
        const id = settings.value.tagmanager_id;

        trackingAllowed.value = true;

        /*
            Assign gtm to vue app
        */
        nuxtApp.vueApp.use(createGtm({
            id,
            enabled: false,
            defer: true,
        }));

        /*
            Set reference of vue gtm to $gtm variable for easier usage
        */
        $gtm = nuxtApp.vueApp.config.globalProperties.$gtm;

        /*
            Enable gtm and send initial page view
        */
        _enableGTM();
        sendPageView();

        /*
            Watch route changes and send page views
        */
        const isSentScrollEvent = ref(false);
        watch(() => route.path, (newValue) => {
            sendPageView(newValue);
            isSentScrollEvent.value = false;
        });

        /*
            Send event to GTM if 90% of the page is reached
        */
        const clientDeviceStore = useClientDeviceStore();
        const scrolledPercentage = computed(() => clientDeviceStore.scrollPositionPercentage);

        watch(() => scrolledPercentage.value, (newValue) => {
            if (newValue > 90) {
                if (!isSentScrollEvent.value && $gtm.enabled()) {
                    sendEvent({
                        event: 'scroll',
                        percent_scrolled: newValue,
                        page: route.path,
                    });
                    isSentScrollEvent.value = true;
                }
            }
        });
    };

    return {
        init,
        sendEvent,
        sendPageView,
        getClientID,
    };
};
