import { useWindowSize } from '@vueuse/core';

const { width, height } = useWindowSize();
const BREAKPOINT = 768;
const BREAKPOINT_TABLET = 1200;

export default defineStore('ClientDeviceStore', {
    state: () => ({
        isDesktopValue: true,
        isMobileValue: false,
        isTabletValue: false,
        isPortraitValue: false,
        isLandscapeValue: true,
        isTouchValue: false,
        windowWidthValue: 0,
        windowHeightValue: 0,
        windowAspectRatioValue: 0,
        scrollHeightValue: 0,
        scrollPositionValue: 0,
        scrollPositionPercentageValue: 0,
    }),

    actions: {
        setOnClient() {
            this.isTouchValue = ('ontouchstart' in window)
                || (navigator.maxTouchPoints > 0)
                || (navigator.msMaxTouchPoints > 0);
        },

        setOnResize() {
            this.isDesktopValue = width.value > BREAKPOINT_TABLET;
            this.isMobileValue = width.value <= BREAKPOINT;
            this.isTabletValue = width.value <= BREAKPOINT_TABLET;
            this.isPortraitValue = width.value < height.value;
            this.isLandscapeValue = width.value >= height.value;
            this.isTouchValue = false;
            this.windowWidthValue = width.value;
            this.windowHeightValue = height.value;
            this.windowAspectRatioValue = height.value / width.value;
            this.scrollPositionValue = 0;
            this.scrollHeightValue = document.documentElement.scrollHeight;
            this.scrollPositionPercentageValue = 100 * (window.scrollY / (
                document.documentElement.scrollHeight - window.innerHeight
            ));
        },

        setOnScroll() {
            this.scrollPositionValue = document.documentElement.scrollTop
            || document.body.scrollTop;
            this.scrollPositionPercentageValue = 100 * (window.scrollY / (
                document.documentElement.scrollHeight - window.innerHeight
            ));
        },
    },

    getters: {
        isDesktop(state) {
            return state.isDesktopValue;
        },

        isMobile(state) {
            return state.isMobileValue;
        },

        isTablet(state) {
            return state.isTabletValue;
        },

        isPortrait(state) {
            return state.isPortraitValue;
        },

        isLandscape(state) {
            return state.isLandscapeValue;
        },

        isTouch(state) {
            return state.isTouchValue;
        },

        windowWidth(state) {
            return state.windowWidthValue;
        },

        windowHeight(state) {
            return state.windowHeightValue;
        },

        windowAspectRatio(state) {
            return state.windowAspectRatioValue;
        },

        scrollPosition(state) {
            return state.scrollPositionValue;
        },

        scrollPositionPercentage(state) {
            return state.scrollPositionPercentageValue;
        },
    },
});
