export default (err, useThrow) => {
    if (process.server) return;

    const config = useRuntimeConfig();
    const { $sentryCaptureException } = useNuxtApp();

    if (config?.public?.IS_STORYBOOK === 'true' || config?.public?.IS_STORYBOOK === true) {
        return;
    }

    if ((config?.public?.USE_SENTRY === 'true' || config?.public?.USE_SENTRY === true) && $sentryCaptureException) {
        $sentryCaptureException(err);
    } else {
        console.error(err);
    }

    if (useThrow) {
        throw createError({
            statusCode: '500',
            statusMessage: err.message,
        });
    }
};
