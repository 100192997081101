export default defineNuxtPlugin(async (nuxtApp) => {
    const route = useRoute();
    const preview = route.query?._storyblok || false;

    if (preview) {
        nuxtApp.hook('page:finish', () => {
            refreshNuxtData();
        });
    }

    return { provide: { preview } };
});
