<template>
  <div
    v-if="imageData?.path"
    ref="observingRef"
    class="atom-image"
    :class="`is-${behaviour}`"
  >
    <transition name="fade-in">
      <NuxtImg
        v-if="allowRender"
        :provider="imageOrigin"
        :width="imageData.width"
        :height="imageData.height"
        :preload="preload || data?.preload"
        :src="imageData.path"
        :alt="imageData.alt"
        :format="imageOrigin === 'storyblok' ? 'webp' : ''"
        :densities="imageData.isSvg ? null : 'x1 x1.5 x2'"
        :loading="disableLazy ? 'eager' : 'lazy'"
        :sizes="imageData.isSvg ? null : sizes"
        :modifiers="imageData.focal ? { filters: { focal: focal } } : null"
      />

      <img
        v-else-if="(
          imageData.path
          && (lazy.isLoaded || disableLazy || isStoryblok))"
        :src="imageData.path"
        :alt="imageData.alt"
        :preload="preload || data?.preload"
      />
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Props for storyblok data */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for manual calling */
    src: {
        type: String,
        default: '',
    },
    width: {
        type: [Number, String],
        default: '',
    },
    height: {
        type: [Number, String],
        default: '',
    },
    alt: {
        type: String,
        default: '',
    },
    isSvg: {
        type: Boolean,
        default: false,
    },
    disableLazy: {
        type: Boolean,
        default: false,
    },
    preload: {
        type: Boolean,
        default: false,
    },
    isStrapi: {
        type: Boolean,
        default: false,
    },
    behaviour: {
        type: String,
        default: 'default',
    },
    sizes: {
        type: [String, Boolean],
        default: '1920px',
    },
    focal: {
        type: String,
        default: null,
    },
    aspectRatio: {
        type: String,
        default: null,
    },
});

/*
      Setup
  */
const storyStore = useStoryStore();

/*
      Get strapi data
  */
const strapiData = computed(() => {
    if (props.data?.strapi_data_key) {
        const data = storyStore.getConnectedStrapiValue(
            props.data.strapi_data_key,
        );

        if (data) {
            return data;
        }
    }

    return null;
});

/*
        Prepare data out of props
  */
let imageData = reactive({
    data: strapiData.value?.url || props?.src || props?.data?.image || props?.data,
    path: props.src,
    width: props.width,
    height: props.height,
    alt: props.alt,
    isSvg: props.isSvg,
    isStrapi: props.isStrapi,
    focal: props.focal,
});

/*
      Check if Storyblok to fix reloading issue in storyblok disable lazyloading
  */
const route = useRoute();
const isStoryblok = route.query._storyblok;

/*
        Get Source Origin
  */
const imageOrigin = computed(() => {
    if (imageData.data?.fieldtype) {
        return 'storyblok';
    }

    if (strapiData.value?.url || imageData.isStrapi) {
        return 'strapi';
    }

    return null;
});

/*
      Get image dimensions
  */
const getImageDimensions = async (imagePath) => {
    const result = {
        height: 0,
        width: 0,
    };

    switch (imageOrigin.value) {
    case 'storyblok':
        result.width = imagePath ? parseInt(imagePath.split('/')[5]?.split('x')[0], 10) : false;
        result.height = imagePath ? parseInt(imagePath.split('/')[5]?.split('x')[1], 10) : false;

        if (props.aspectRatio && result.width) {
            const width = parseInt(result.width, 10);
            const [ratioX, ratioY] = props.aspectRatio.split(':');
            result.width = width;
            result.height = (width / ratioX) * ratioY;
        }
        break;
    case 'strapi':
        result.width = props.data.width || false;
        result.height = props.data.height || false;
        break;

    default:
        break;
    }
    return result;
};

/*
        Set the final data
*/
const allowRender = ref(false);
const setImageData = async () => {
    const { data } = imageData;

    // It's switch case here because there could also be strapi as origin in other projects
    switch (imageOrigin.value) {
    case 'storyblok':
        imageData.path = data.filename;
        break;

    case 'strapi':
        imageData.path = strapiData.value?.url || props.data?.image?.url || props.data?.url;
        break;

    default:
        imageData.path = props.src;
        break;
    }

    imageData.isSvg = imageData?.path?.includes('.svg');

    /* Set dimensions for image */
    const { height, width } = await getImageDimensions(imageData.path);

    imageData.width = width;
    imageData.height = height;
    allowRender.value = true;
};

setImageData();

/*
      Lazyloading
  */
const observingRef = ref(null);
const lazy = ref(
    useImageLazyLoading(observingRef),
);

watch(() => props.data, (newValue) => {
    imageData = reactive({
        data: strapiData.value?.url || props?.src || newValue?.image || newValue,
        path: props.src,
        width: props.width,
        height: props.height,
        alt: props.alt,
        isSvg: props.isSvg,
        isStrapi: props.isStrapi,
    });
    setImageData();
});
</script>

  <style lang="scss">
.atom-image {
    @include component-spacing(10px, 30px);

    @include top-level-only {
        @include default-content-columns;

    }

    display: block;
    font-size: 0;
    line-height: 0;

    img {
        width: 100%;
        height: auto;

        .app-header-quickshop__logo &{
            width: auto;
            height: 100%;
        }
    }

    &.is-contain {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    &.is-cover {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    .shop-products-slider__image & {
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    .shop-product-card-image & {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center center;
        }
    }

    .shop-product-card-image__brand &,
    .shop-product-card__tip-logo & {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top left;
        }
    }

    .shop-product-card__tip-logo & {
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .shop-product-card-speaker & {
        position: relative;
        width: 31px;
        height: 31px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    .block-teaser-blog-card__author &,
    .block-detail-blog__info-author & {
        overflow: hidden;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .shop-products-mix__head-speaker-image & {
        overflow: hidden;
        width: 80px;
        height: 80px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .shop-product-related-products__head-speaker-image & {
        overflow: hidden;
        width: 80px;
        height: 80px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .block-information-cards__item-image & {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    .shop-cart-popup__image & {
        position: relative;

        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 70%;
            height: 70%;
            margin: auto;
            object-fit: contain;
            object-position: center center;
        }
    }

    .block-hero-products__content-attribute & {
        height: 24px;

        img {
            width: auto;
            height: 100%;
        }
    }

    & + .atom-heading {
        @include fluid('margin-top', 10px, 20px);
    }

    & + .block-accordion {
        @include fluid('margin-top', 10px, 20px);
    }
}
  </style>
