import { useWindowSize } from '@vueuse/core';

export default () => {
    const body = document.documentElement;

    const { height, width } = useWindowSize({
        includeScrollbar: false,
    });

    body.style.setProperty('--app-height', `${height.value}px`);
    body.style.setProperty('--app-width', `${width.value}px`);

    watch(() => height.value, (newValue) => {
        body.style.setProperty('--app-height', `${newValue}px`);
    }, { immediate: true });

    watch(() => width.value, (newValue) => {
        body.style.setProperty('--app-width', `${newValue}px`);
    }, { immediate: true });
};
